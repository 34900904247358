export enum AuthStrategies {
    USER,
    REGISTERED,
    REGISTER_CONFIRMED,
    UNAUTHORIZED,
    BOUGHT,
    INSTANCE_IN_CART,
    IS_ADMIN,
    IS_BOOKKEEPER
}
