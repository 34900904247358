import { createDataHook } from 'next-data-hooks';

import { backend } from '../../utils/_config/axios';
import { ArticlePageData } from './useArticlePage.hook';

export const useNotAuthorizedPage = createDataHook(
    'AuthGuardPage',
    async ({ locale }) => {
        const localeRes = !locale || locale === 'catchAll' ? 'en' : locale;
        const result = await backend().get<ArticlePageData[]>(
            `api/strapi/confirmation-pages?_locale=${localeRes}&urlReadablePageTitle=${getNotAuthorizedPageName(
                localeRes
            )}`
        );
        return result?.data?.[0] ?? null;
    }
);

const getNotAuthorizedPageName = (locale?: string): string => {
    switch (locale) {
        case 'de':
            return 'zugriff-verweigert';
        case 'en':
            return 'access-restricted';
        default:
            return 'access-restricted';
    }
};
