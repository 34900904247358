import React from 'react';

import { useNotAuthorizedPage } from '../../../../api/hooks/useNotAuthorizedPage.hook';
import { ArticlePageTypes } from '../../../../utils/_enums/article-page-types';
import { ArticlePage } from '../../../../views/article-page/article-page';

export const AuthGuardError: React.FC & { dataHooks: any } = () => {
    const data = useNotAuthorizedPage();

    if (data) {
        return (
            <ArticlePage
                type={ArticlePageTypes.CONFIRMATION_PAGE}
                pageData={data}
            />
        );
    } else {
        return <div />;
    }
};

AuthGuardError.dataHooks = [useNotAuthorizedPage];
