import {GetServerSideProps} from 'next';
import {getDataHooksProps} from 'next-data-hooks';
import React from 'react';

import {AuthGuard} from '../components/_shared/auth-guard/auth-guard';
import {HeaderFooterWrapper} from '../components/_shared/header-footer-wrapper/header-footer-wrapper';
import {AuthStrategies} from '../utils/_enums/authStrategies.enum';
import {resolveLanguageAndRedirect} from '../utils/_helper/resolve-language-and-redirect-helper';
import {setHeaders} from '../utils/_helper/set-headers';
import {AuthentificationPage} from '../views/login/login-page';
import MyApp from './_app';

const AuthentificationPagePage = (): JSX.Element => {

    return (
        <AuthGuard strategies={[AuthStrategies.UNAUTHORIZED]}>
            <AuthentificationPage/>
        </AuthGuard>
    );
};

export default AuthentificationPagePage;

export const getServerSideProps: GetServerSideProps = async (context) => {
    setHeaders(context);

    const redirect = await resolveLanguageAndRedirect(context);

    if (redirect) return {redirect};
    const dataHooksProps = await getDataHooksProps({
        context,
        dataHooks: [
            ...HeaderFooterWrapper.dataHooks,
            ...AuthentificationPage.dataHooks,
            ...AuthGuard.dataHooks,
            ...MyApp.dataHooks
        ]
    });
    return {
        props: {...dataHooksProps}
    };
};
