import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {fetchCurrentProduct, resetFetchCurrentProductState,
     useAppSelector} from '../../../redux';
import {AuthStrategies} from '../../../utils/_enums/authStrategies.enum';
import {useMounted} from '../../../utils/_hooks/useMounted.hook';
import {HeaderFooterWrapper} from '../header-footer-wrapper/header-footer-wrapper';
import {Loading} from '../loading/loading';
import {AuthGuardError} from './auth-guard-error/auth-guard-error';

export interface Props {
    strategies?: AuthStrategies[];
}

export const AuthGuard: React.FC<Props> & { dataHooks: any } = ({
                                                                    strategies,
                                                                    children
                                                                }) => {
    const dispatch = useDispatch();
    const {hasMounted} = useMounted();
    const {
        hasRegistered,
        jwt,
        registerConfirmed,
        showCheckoutConfirmation,
        instanceInCart,
        maxCheckoutTime,
        role,
        fetchCurrentProductModelStatus,
        loginPending,
        fetchUserPending,
        authRoutingActive
    } = useAppSelector((state) => ({
        hasRegistered: state.authentification.hasRegistered,
        loginPending: state.authentification.pending,
        fetchUserPending: state.userSlice.fetchUser.pending,
        jwt: state.authentification.jwt,
        registerConfirmed: state.authentification.hasConfirmedRegistration,
        showCheckoutConfirmation: state.checkoutSlice.showCheckoutConfirmation,
        instanceInCart: state.checkoutSlice.basket,
        maxCheckoutTime: state.checkoutSlice.maxTime,
        role: state.userSlice.user?.role.type,
        fetchCurrentProductModelStatus: state.checkoutSlice.fetchCurrentProductModelStatus,
        authRoutingActive: state.authentification.authRoutingActive
    }));

    useEffect(() => {
        if (!hasMounted) return;
        if (strategies?.includes(AuthStrategies.INSTANCE_IN_CART)
            && !instanceInCart && jwt) {
            dispatch(fetchCurrentProduct());
        }
    }, [hasMounted, jwt]);

    useEffect(() => {
        if (fetchCurrentProductModelStatus) {
            dispatch(resetFetchCurrentProductState());
        }
    }, [fetchCurrentProductModelStatus]);

    const checkValidAuth = (strategy: AuthStrategies): boolean | undefined => {
        switch (strategy) {
            case AuthStrategies.REGISTERED: {
                return hasRegistered;
            }
            case AuthStrategies.IS_ADMIN: {
                return role === 'admin';
            }
            case AuthStrategies.IS_BOOKKEEPER: {
                return role === 'bookkeeper';
            }
            case AuthStrategies.REGISTER_CONFIRMED: {
                return registerConfirmed !== undefined;
            }
            case AuthStrategies.UNAUTHORIZED: {
                return !jwt;
            }
            case AuthStrategies.BOUGHT: {
                return showCheckoutConfirmation;
            }
            case AuthStrategies.INSTANCE_IN_CART: {
                return instanceInCart === undefined ||
                    maxCheckoutTime === undefined
                        ? false
                        : Boolean(instanceInCart)
                        &&maxCheckoutTime -
                        Math.floor(
                            (new Date(
                                instanceInCart.currentTimeStamp)
                                .getTime() -
                                new Date(
                                    instanceInCart.createdAt)
                                .getTime()) /
                            1000
                        ) >
                        0;
            }
            case AuthStrategies.USER: {
                return fetchUserPending || (loginPending && !jwt)
                    ? undefined
                    : Boolean(jwt);
            }
        }
    };

    if (!hasMounted) {
        return null;
    }
    if (authRoutingActive) {
        return <Loading text={''} textDe={''}/>;
    }
    if (strategies) {
        const pending = strategies.some(
            (strategy) => checkValidAuth(strategy) === undefined
        );
        const valid = strategies.every((strategy) => checkValidAuth(strategy));
        if (pending) {
            return <Loading text={''} textDe={''}/>;
        } else if (valid) {
            return <>{children}</>;
        } else {
            return <AuthGuardError/>;
        }
    } else {
        return <div/>
    }
};

AuthGuard.defaultProps = {strategies: [AuthStrategies.USER]};

AuthGuard.dataHooks = [
    ...HeaderFooterWrapper.dataHooks,
    ...AuthGuardError.dataHooks
];
