import React from 'react';

import { useLoginPage } from '../../api/hooks/useLoginPage.hook';
import FaqBox from '../../components/_shared/faq-box/faq-box';
import { DefaultHeader } from '../../components/_shared/header/default-header/default-header';
import { HeaderFooterWrapper } from '../../components/_shared/header-footer-wrapper/header-footer-wrapper';
import { Link } from '../../components/_shared/link/link';
import { MetaTagsResolver } from '../../components/_shared/meta-tags-resolver/meta-tags-resolver';
import { Stage } from '../../components/_shared/stage/stage';
import TextSection from '../../components/_shared/text-section/text-section';
import { Typography } from '../../components/_shared/typography/typography';
import { LoginForm } from '../../components/login/login-form/login-form';
import { RoutePath } from '../../utils/_enums/route-path.enum';
import { TypographyVariantsEnum } from '../../utils/_enums/typeography-variants.enum';
import styles from './login-page.module.scss';

export const AuthentificationPage: React.FC & { dataHooks: any } = () => {
    const data = useLoginPage();

    if (data) {
        return (
            <HeaderFooterWrapper
                className={styles['authentification-page']}
                header={
                    <DefaultHeader availableLanguages={data?.localizations} />
                }
                stage={
                    <Stage
                        orientation={
                            data?.stage?.componentStyles?.textAlign ?? ''
                        }
                        inverted={data?.stage?.componentStyles?.inverted ?? ''}
                        background={data?.stage?.background?.url}
                        title={
                            (data?.stage?.headline
                                ?.headlineContent as string) ?? ''
                        }
                        titleVariant={data.stage?.headline?.style}
                        logo={data?.stage?.showLogo ?? false}
                        description={
                            (data?.stage?.paragraph?.content as string) ?? ''
                        }
                        size="Small"
                    />
                }
            >
                <MetaTagsResolver tags={data.meta} />
                <div className={styles['authentification-page-upper-wrapper']}>
                    <div>
                        <TextSection
                            title={
                                data?.loginHeader?.title?.headlineContent ?? ''
                            }
                            description={
                                data?.loginHeader?.description?.content ?? ''
                            }
                        />
                        <div className={styles['register-link']}>
                            <Link href={RoutePath.REGISTER}>
                                {data.registerLinkText}
                            </Link>
                        </div>
                    </div>
                    <div>
                        <Typography
                            variant={TypographyVariantsEnum.HEADING2_BOLD}
                            className={styles['login-form-header']}
                        >
                            {data.loginFormHeader?.headlineContent}
                        </Typography>
                        <LoginForm />
                    </div>
                </div>
                <div className={styles['authentification-page-lower-wrapper']}>
                    {data?.faqbox &&
                        data?.faqbox?.map(({Headline, fa_qs}) => (
                            <FaqBox
                                key={Headline?.headlineContent ?? ''}
                                Headline={Headline ?? ''}
                                fa_qs={fa_qs ?? []}
                            />
                        ))}
                </div>
            </HeaderFooterWrapper>
        );
    } else {
        return <div />;
    }
};

AuthentificationPage.dataHooks = [useLoginPage, ...MetaTagsResolver.dataHooks];
