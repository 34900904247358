import React from 'react';
import { CircleSpinner } from 'react-spinners-kit';

import { Spacer } from '../spacer/spacer';
import { Typography } from '../typography/typography';
import styles from './loading.module.scss';
export interface Props {
    text?: string;
    textDe?: string;
}

/**
 * An Loading React Component.
 * @author
 * @version 0.1
 */
export const Loading: React.FC<Props> = ({ text, textDe }) => {
    return (
        <div className={styles.loading}>
            <CircleSpinner size={100} color="#000" />
            <Spacer height="20px" />
            {text && <Typography>{text}</Typography>}
            <Spacer height="5px" />
            {textDe && <Typography>{textDe}</Typography>}
        </div>
    );
};
